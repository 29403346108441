var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-vehicles"},[_c('v-card',{staticClass:"pa-0 pb-10",attrs:{"tile":""}},[(_vm.loading)?_c('Loader'):[_c('v-card-title',[_c('h4',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$t("myVehicle.title"))+" ")]),_c('v-spacer'),_c('v-btn',{class:{ 'mr-4': !_vm.$vuetify.breakpoint.xsOnly, 'mb-2': _vm.$vuetify.breakpoint.smAndDown },attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'AddVehicle' })}}},[_vm._v(" "+_vm._s(_vm.$t("myVehicle.add"))+" ")]),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'AddFreight' })}}},[_vm._v(" "+_vm._s(_vm.$t("myVehicle.addFracht"))+" ")])],1),_c('Tabs',{staticClass:"mb-4",attrs:{"actions":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":""},on:{"change":_vm.toggleOnlyMain},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('freightList.showOnlyMain')))])]},proxy:true}]),model:{value:(_vm.filters.onlyMine.value),callback:function ($$v) {_vm.$set(_vm.filters.onlyMine, "value", $$v)},expression:"filters.onlyMine.value"}})]},proxy:true}])}),(_vm.$store.getters['market/loading'])?_c('Loader'):_c('real-time-data-table',{ref:"dataTable",attrs:{"headers":_vm.headers,"endpoint":"/VehicleOrder/GetFiltered","item-class":_vm.itemRowBackground,"sort-by":['publishDate'],"sort-desc":[true]},on:{"click:row":function($event){return _vm.openDetails($event)}},scopedSlots:_vm._u([{key:"item.loadingPlaces",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('CountryFlag',{staticClass:"mr-1",attrs:{"country":item.loadingPlaces[0].country}}),_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(" "+_vm._s(item.loadingPlaces[0].country.toUpperCase())+", "),_c('span',[_vm._v(_vm._s(item.loadingPlaces[0].postalCode))]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(item.loadingPlaces[0].city)+" ")]),_c('div',{staticClass:"lightText--text"},[_c('span',[_vm._v(_vm._s(_vm._f("dateFormat")(item.loadingPlaces[0].date))+" ")]),(item.loadingPlaces[0].from && item.loadingPlaces[0].to)?_c('span',[_vm._v(_vm._s(item.loadingPlaces[0].from)+" - "+_vm._s(item.loadingPlaces[0].to))]):_vm._e()]),(item.loadingDistanceFromFilter)?_c('div',[_c('span',[_vm._v(_vm._s(item.loadingDistanceFromFilter))])]):_vm._e()])],1),(item.loadingPlaces.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"more-data ml-2"},'div',attrs,false),on),[_vm._v(" +"+_vm._s(item.loadingPlaces.length - 1)+" ")])]}}],null,true)},_vm._l((item.loadingPlaces),function(location,index){return _c('div',{key:index},[(index !== 0)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"line-height":"1.25"}},[_c('CountryFlag',{staticClass:"mr-1",attrs:{"country":location.country}}),_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(location.country.toUpperCase())+", "+_vm._s(location.postalCode)+" "+_vm._s(location.city))]),_c('span',[_vm._v(_vm._s(_vm._f("dateFormat")(location.date))),(location.from && location.to)?_c('span',[_vm._v(" "+_vm._s(location.from)+" - "+_vm._s(location.to))]):_vm._e()])])],1):_vm._e()])}),0):_vm._e()],1)]}},{key:"item.unloadingPlaces",fn:function(ref){
var item = ref.item;
return [(item.unloadingPlaces.length > 0)?_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('CountryFlag',{staticClass:"mr-1",attrs:{"country":item.unloadingPlaces[0].country}}),_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_c('span',[_vm._v(" "+_vm._s(item.unloadingPlaces[0].country.toUpperCase())+", "+_vm._s(item.unloadingPlaces[0].postalCode)),_c('br'),_vm._v(" "+_vm._s(item.unloadingPlaces[0].city)+" ")]),_c('div',{staticClass:"lightText--text"},[_c('span',[_vm._v(_vm._s(_vm._f("dateFormat")(item.unloadingPlaces[0].date))+" ")]),(item.unloadingPlaces[0].from && item.unloadingPlaces[0].to)?_c('span',[_vm._v(_vm._s(item.unloadingPlaces[0].from)+" - "+_vm._s(item.unloadingPlaces[0].to))]):_vm._e()]),(item.unloadingDistanceFromFilter)?_c('div',[_c('span',[_vm._v(_vm._s(item.unloadingDistanceFromFilter))])]):_vm._e()])])],1),(item.unloadingPlaces.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"more-data ml-2"},'div',attrs,false),on),[_vm._v("+"+_vm._s(item.unloadingPlaces.length - 1))])]}}],null,true)},_vm._l((item.unloadingPlaces),function(location,index){return _c('div',{key:index},[(index !== 0)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"line-height":"1.25"}},[_c('CountryFlag',{staticClass:"mr-1",attrs:{"country":location.country}}),_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(location.country.toUpperCase())+", "+_vm._s(location.postalCode)+" "+_vm._s(location.city))]),_c('span',[_vm._v(_vm._s(_vm._f("dateFormat")(location.date))+" "),(location.from && location.to)?_c('span',[_vm._v(_vm._s(location.from)+" - "+_vm._s(location.to))]):_vm._e()])])],1):_vm._e()])}),0):_vm._e()],1):_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t('myVehicle.anyDestination'))+" ")])]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center ml-4"},[_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.startDate)+" "),_c('br'),_vm._v(" "+_vm._s(item.startTime)+" ")]),_c('span',{staticClass:"px-4"},[_vm._v("-")]),_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.endDate)+" "),_c('br'),_vm._v(" "+_vm._s(item.endTime)+" ")])])]}},{key:"item.vehicleDetails",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(item.iconName)?_c('DisplayIcon',{attrs:{"icon-name":item.iconName,"size":"26"}}):_c('v-icon',[_vm._v("$truck")])],1)]}}],null,true)},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.type))])]),_c('div',{staticClass:"d-flex flex-column ml-2"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.vehicleDetails))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.vehicleDetailsSecondLine))])])],1)]}},{key:"item.publishDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.publishDate,'DD.MM.YYYY'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.publishDate,'HH:mm'))+" ")])]}},{key:"item.publisher",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center cursor-pointer"},[_c('StatusAvatar',{staticClass:"mr-2",attrs:{"userId":item.publisher.id,"size":26,"showStatus":""}}),_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(item.publisher.name)+" "+_vm._s(item.publisher.surname))]),_c('v-tooltip',{attrs:{"bottom":"","min-width":"320px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"lightText--text"},'div',attrs,false),on),[_vm._v(_vm._s(_vm._f("trimText")(item.publisher.company.name,20)))])]}}],null,true)},[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"6"}},[_c('p',[_vm._v(_vm._s(item.publisher.company.name)),_c('br'),_vm._v(" NIP: "+_vm._s(item.publisher.company.vatNumber))]),_c('p',[_vm._v(_vm._s(_vm.$t('offerDetails.address'))+":"),_c('br'),_vm._v(" "+_vm._s(item.publisher.company.street)),_c('br'),_vm._v(" "+_vm._s(item.publisher.company.zipCode)+", "+_vm._s(item.publisher.company.city))])]),_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.$t('offerDetails.averageRating'))+": "),_c('v-rating',{staticClass:"mb-4",attrs:{"length":"5","size":"16","color":"accent","value":item.publisher.company.averageRating}}),_vm._v(" "+_vm._s(_vm.$t('offerDetails.paymentRating'))+": "),_c('v-rating',{attrs:{"length":"5","size":"16","color":"accent","value":item.publisher.company.averagePaymentRating}})],1)],1)],1)],1)],1)]}},{key:"item.conversation",fn:function(ref){
var item = ref.item;
return [(_vm.$store.getters.userCommonData.id !== item.publisher.id)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"icon":"","color":hover ? 'primary' : '',"size":"18"},on:{"click":function($event){$event.stopPropagation();return _vm.startConversationWithUser(item)}}},[_vm._v("$conversation")])]}}],null,true)}):_vm._e()]}},{key:"item.mail",fn:function(ref){
var item = ref.item;
return [(_vm.$store.getters.userCommonData.id !== item.publisher.id)?_c('DataTablePopupCell',{attrs:{"subject":item,"email":item.publisher.email,"clickType":_vm.ClickEmail,"orderId":item.id}}):_vm._e()]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [(_vm.$store.getters.userCommonData.id !== item.publisher.id)?_c('DataTablePopupCell',{attrs:{"phone":item.publisher.phone,"clickType":_vm.ClickPhone,"orderId":item.id}}):_vm._e()]}},{key:"item.private",fn:function(ref){
var item = ref.item;
return [(item.isPrivate)?_c('v-icon',{attrs:{"size":"18"}},[_vm._v("$private")]):_vm._e()]}},{key:"item.hidden",fn:function(ref){
var item = ref.item;
return [(item.isHidden)?_c('v-icon',{attrs:{"size":"18"}},[_vm._v("$hidden")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":hover ? 'primary' : 'separator'}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}],null,true)})]}}],null,true)},[_c('v-list',{staticClass:"py-0"},[(_vm.myVehicleMenu)?[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.refreshOrder(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('marketListOptions.refresh'))+" ")])],1),_c('v-divider',{staticClass:"mx-4"}),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.republishOrder(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('marketListOptions.resend'))+" ")])],1),_c('v-divider',{staticClass:"mx-4"}),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.archiveOrder(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('marketListOptions.remove'))+" ")])],1),_c('v-divider',{staticClass:"mx-4"}),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openDetails(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('marketListOptions.details'))+" ")])],1)]:_vm._e(),(_vm.myVehicleArchiveMenu)?[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.republishOrder(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('marketListOptions.resend'))+" ")])],1),_c('v-divider',{staticClass:"mx-4"}),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openDetails(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('marketListOptions.details'))+" ")])],1)]:_vm._e()],2)],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.$t("users.slotNoData")))])]},proxy:true}])})]],2),(_vm.vehicleId)?_c('OfferDetailsDialog',{attrs:{"value":_vm.offerDetailsActive,"itemId":_vm.vehicleId,"isVehicle":true},on:{"closed":_vm.closeOfferDetailsDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }