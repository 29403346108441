<template>
  <div>
    <v-img
      v-if="iconName && iconName.length > 0"
      :src="iconPath"
      :width="size"
      eager
      @click="click"
    />
  </div>
</template>

<script>
export default {
  name: 'DisplayIcon',
  props: {
    iconName: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: '40px'
    }
  },
  computed: {
    iconPath () {
      if (!this.iconName || this.iconName.length < 1) {
        return ''
      }
      return require(`@/assets/icons/${this.iconName}`)
    }
  },
  methods: {
    click () {
      this.$emit('icon-clicked', this.iconName)
    }
  }
}
</script>
