<template>
  <div>
    <v-card-text v-if="showTotal" class="font-weight-bold primary--text">
      <div class="d-flex align-center justify-space-between">
        <div>{{$t("marketTableHeader.offersFound")}} - {{ totalItems }}</div>
        <div @click="expand" class="pointer">
          <span v-if="expandedTab">{{$t("marketTableHeader.hide")}} <v-icon color="primary" size="18" style="transform: rotate(180deg);">$sort</v-icon></span>
          <span v-else>{{$t("marketTableHeader.show")}} <v-icon color="primary" size="18">$sort</v-icon></span>
        </div>
      </div>
    </v-card-text>
    <v-divider v-if="showTotal" />
    <v-data-table
      ref="dataTable"
      :items="items"
      :headers="headers"
      hide-default-footer
      :item-class="itemClass"
      :items-per-page="-1"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      @click:row="$emit('click:row', $event)"
      @item-expanded="$emit('item-expanded', $event)"
      @update:options="updateOptions"
    >
      <template
        v-for="field in Object.keys($scopedSlots)"
        v-slot:[field]="{ item, headers }"
      >
        <slot :name="field" :item="item" :headers="headers" />
      </template>
      <template v-slot:footer>
        <div
          v-if="items.length < totalItems"
          v-intersect="fetchData"
          class="d-flex justify-center my-4"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          />
        </div>
      </template>
    </v-data-table>
    <v-fab-transition>
      <v-btn v-if="showScrollToTopButton" color="primary" icon fixed bottom right @click="scrollToTop">
        <v-icon size="32">$top</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    headers: {
      type: Array,
      default: () => ([])
    },
    endpoint: {
      type: String,
      default: ''
    },
    itemClass: {
      type: [String, Function],
      default: 'bgRow'
    },
    additionalParams: {
      type: Object,
      default: () => ({})
    },
    mappingFunction: {
      type: Function,
      default: items => (items)
    },
    sortBy: {
      type: [String, Array],
      default: undefined
    },
    sortDesc: {
      type: [String, Array],
      default: undefined
    },
    showTotal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      itemsPerPage: 20,
      page: 1,
      sortProperties: [],
      getDataTimeout: null
    }
  },
  computed: {
    totalItems () {
      return this.$store.getters['market/totalItems']
        ? this.$store.getters['market/totalItems']
        : 0
    },
    items () {
      return this.$store.getters['market/items']
    },
    showScrollToTopButton () {
      return this.$store.getters['market/showScrollToTopButton']
    },
    ...mapGetters({
      expandedTab: 'market/expandedTab'
    })
  },
  watch: {
    additionalParams () {
      this.getDataDebounce()
    }
  },
  async created () {
    document.addEventListener('scroll', this.scrollHandle)
    await this.getDataDebounce(0)
  },
  destroyed () {
    document.removeEventListener('scroll', this.scrollHandle)
    clearTimeout(this.searchDebounce)
  },
  methods: {
    fetchData (entries, observer, isIntersecting) {
      if (!isIntersecting) {
        return
      }
      this.page = this.page + 1
      this.getDataDebounce(500, true)
    },
    async updateOptions (options) {
      this.$store.commit('market/CLEAR_ITEMS')
      this.page = 1
      this.sortProperties = options.sortBy.map((element, index) => {
        return {
          propertyName: element,
          priority: index,
          type: options.sortDesc[index] ? 1 : 0
        }
      })

      await this.getDataDebounce(0)
    },
    async getDataDebounce (timeout) {
      if (isNaN(timeout) || timeout < 0) {
        timeout = 250
      }
      clearTimeout(this.getDataTimeout)
      this.getDataTimeout = setTimeout(this.getData, timeout)
    },
    async getData () {
      if (
        !this.$store.getters['market/currentTab'] ||
        this.$store.getters['market/currentTab'].newTab
      ) {
        return
      }
      let url
      if (this.endpoint.includes('?')) {
        url = this.endpoint + '&'
      } else {
        url = this.endpoint + '?'
      }

      url += `page=${this.page}&pageSize=${this.itemsPerPage}&filterId=${this.$store.getters['market/currentTab'].id}`

      if (this.sortProperties) {
        url += '&' + this.getStringSortableProperties()
      }

      if (this.additionalParams) {
        Object.keys(this.additionalParams).forEach((key) => {
          url += `&${key}=${this.additionalParams[key]}`
        })
      }

      const response = await this.$http.get(url)

      if (this.mappingFunction) {
        this.$store.commit(
          'market/UPDATE_ITEMS',
          this.mappingFunction(response.data)
        )
      } else {
        this.$store.commit('market/UPDATE_ITEMS', response.data)
      }
    },
    getStringSortableProperties () {
      let result = ''
      this.sortProperties.forEach((item, index) => {
        if (result.length > 0) {
          result += '&'
        }
        const name = `sortableProperties[${index}]`
        result += `${name}.type=${item.type}&${name}.propertyName=${item.propertyName}&${name}.priority=${item.priority}`
      })
      return result
    },
    expand () {
      this.$store.commit('market/SET_EXPANDED_TAB', !this.expandedTab)
    },
    scrollHandle () {
      if (document.documentElement.scrollTop < 200 && this.showScrollToTopButton) {
        this.$store.commit('market/SET_SHOW_SCROLL_TO_TOP_BUTTON', false)
      }
      if (document.documentElement.scrollTop > 200 && !this.showScrollToTopButton) {
        this.$store.commit('market/SET_SHOW_SCROLL_TO_TOP_BUTTON', true)
      }
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style>
.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon,
.theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon {
  margin-left: 10px;
  padding: 1px 0;
}

.theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon,
.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon:hover,
.theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon:hover {
  color: var(--v-primary-base);
}

th span {
  font-weight: normal;
  color: black;
}

.theme--light.v-data-table {
  color: black;
}

.theme--light.v-data-table td {
  border-bottom: thin solid var(--v-background-base) !important;
}

th>span{
  padding-right: 4px;
}

th span{
    display: table-cell !important;
    align-items: center;
}

th.sortable > * {
  padding-top:1.9px
}

th.sortable > .span.v-icon {
  padding-top:2px
}
</style>
